import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

// Servicios
import { AuthService } from "src/app/services/security/auth.service";
import { ControlService } from "src/app/services/common/control.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { MenuService } from "src/app/services/common/menu.service";

// Clases
import { Usuario } from "src/app/models/entities/business/usuario.entity";
import { ResponseApp } from "src/app/models/entities/entity.index";

// Constantes
import {
  MESSAGE,
  MENU,
  APP,
  PATH_URL,
} from "src/app/constants/constants.index";

// Interfaces
import { IIdentity } from "src/app/models/interfaces/interfaces.index";

// Enums
import { EnumTipoMenu } from "src/app/models/enums/tipo.enum";
import { Router } from "@angular/router";

@Component({
  selector: "app-recovery-password",
  templateUrl: "./recovery-password.page.html",
  styleUrls: ["./recovery-password.page.scss"],
})
export class RecoveryPasswordPage implements OnInit {
  title = APP.NAME;
  icono = "";
  formGroup: FormGroup;
  usuario: Usuario;
  resp: ResponseApp;
  textoMensaje = "";
  urls: IIdentity[] = MENU.WEB.PRE_HOLDER;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private controlService: ControlService,
    private sbcService: SbcService,
    private menuService: MenuService,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.resolver_menu();
    this.configurar_formulario();
  }

  resolver_menu() {
    this.sbcService
      .resolver_menu(EnumTipoMenu.PreHolder)
      .subscribe((resp: string | IIdentity[]) => {
        if (typeof resp !== "string") {
          this.urls = this.menuService.reemplazar_menu(this.urls, resp);
        }
      });

    this.sbcService.resolver_icono().subscribe((icono: string) => {
      this.icono = icono;
    });
  }

  configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      userName: ["", [Validators.required]],
      documento: ["", [Validators.required]],
      email: ["", [Validators.required]],
    });
  }

  recovery_password() {
    this.controlService
      .mostrarLoading(MESSAGE.RECOVERY_PASSWORD.ENVIANDO)
      .then(() => {
        this.auth
          .recovery_password(this.formGroup.value)
          .then((resp: ResponseApp) => {
            this.resp = resp;
            this.textoMensaje =
              resp.Status == "OK"
                ? MESSAGE.RECOVERY_PASSWORD.OK
                : MESSAGE.RECOVERY_PASSWORD.ERROR;
            this.controlService.ocultar_loading();
          });
      });
  }

  ir_login() {
    this.router.navigate([PATH_URL.WEB.LOGIN.DEFAULT]);
  }
}
