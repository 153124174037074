import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Routes, RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";

//Modulos
import { ComponentsWebModule } from "src/app/components/components-web.module";

import { RecoveryPasswordPage } from "./recovery-password.page";

const routes: Routes = [
  {
    path: "recovery",
    component: RecoveryPasswordPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    ComponentsWebModule,
  ],
  declarations: [RecoveryPasswordPage],
})
export class RecoveryPasswordPageModule {}
